.event-wrapper {
  /* display */
  display: flex;
  flex-direction: row;
  /* box */
  width: 95%;
  padding-bottom: 60px;
  position: relative;
}
.event-content {
  /* box */
  width: 45%;
}
.event-header {
  /* display */
  display: flex;
  flex-direction: column;
  align-items: left;
}
.event-title {
  /* font */
  color: var(--brown);
  font-family: Bold;
  font-size: 28px;
  line-height: 1.3;
  /* box */
  padding-bottom: 15px;
}
.event-date {
  /* font */
  color: var(--green);
  font-family: Medium;
  font-size: 18px;
}
.event-location {
  /* font */
  color: var(--green);
  font-family: Medium;
  font-size: 18px;
}
.event-text-container {
  /* font */
  color: var(--brown);
  font-family: Regular;
  font-size: 16px;
  line-height: 1.8;
  /* color block */
  background-color: var(--white);
  box-shadow: 0 2px 10px var(--blackopa);
  /* box */
  width: 100%;
  padding: 20px 30px;
  border-radius: 8px;
  margin-top: 40px;
  box-sizing: border-box;
}
.event-wrapper button {
  /* font */
  color: var(--brown);
  font-family: Medium;
  font-size: 18px;
  text-decoration: none;
  transition: 0.1s;
  cursor: pointer;
  /* box */
  position: absolute;
  top: 0;
  right: 0;
  background: none;
  border: none;
}
.event-wrapper button:hover{
  /* font */
  color: var(--yellow);
  border-bottom: 2px var(--yellow) solid;
}
.event-poster {
  /* box */
  width: 50%;
  margin-top: 60px;
  margin-left: 5%;
}
.event-poster img {
  /* box */
  width: 100%;
  border-radius: 8px;
}