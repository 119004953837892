/* 1. Card Layout */
.profile-container{
    /* color block */
    position: relative;
    /* box */
    width: 100%;
    height: 100%;
    margin-bottom: 60px;
}
.profile-tabs{
    /* display */
    display: flex;
    justify-content: space-around;
    /* color block */
    background-color: var(--palegreen);
    position: absolute;
    top: 0;
    left: 0;
    /* box */
    width: 63%;
}
.profile-tabs button{
    /* color block */
    background-color: var(--green);
    /* font */
    color: var(--white);
    font-family: Medium;
    font-size: 18px;
    cursor: pointer;
    /* box */
    padding: 20px 0;
    border: none;
    border-radius: 30px 30px 0 0;
    flex: 1;
    transition: background-color 0.1s;
}
.profile-tabs button.active{
    /* color block */
    background-color: var(--yellow);
    /* box */
    padding: 20px 0;
    transform: none;
    border-radius: 30px 30px 0 0;
}
.profile-whitecard{
    /* color block */
    background-color: var(--white);
    position: relative;
    top: 65px;
    /* box */
    width: 100%;
    min-height: 460px;
    border-radius: 0 8px 8px 8px;
    box-shadow: 0 2px 10px var(--blackopa);
}
.profile-content{
    /* display */
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    /* box */
    padding: 30px calc((100vw - 400px) * 0.55 * 0.06);
    position: relative;
}
.detail{
    /* display */
    display: grid;
    /* box */
    grid-template-columns: repeat(3, calc((100vw - 400px) * 0.55 * 0.33));
    gap: 0 20px;
    margin-left: calc((100vw - 400px) * 0.55 * 0.1);
}
.vertical-table{
    /* display */
    display: flex;
    /* box */
    padding: 40px;
}
.profile-table{
    /* box */
    padding: 40px;
}

/* 2. Basic Info */
.basic{
    /* display */
    display: flex;
    flex-direction: column;
    align-items: center;
    /* box */
    width: 200px;
    padding-right: calc((100vw - 400px) * 0.55 * 0.06);
    padding-bottom: 60px;
}
.basic img{
    /* box */
    width: 120px;
    border: 2px var(--white) solid;
    border-radius: 50%;
    margin-top: 40px;
}
.basic-info{
    /* display */
    display: flex;
    flex-direction: column;
    align-items: center;
    /* box */
    margin-top: 20px;
}

/* 3. Fixed Table */
.fixed-table {
    /* box */
    width: 100%;
    border-collapse: collapse;
    table-layout: fixed;
}
.fixed-table th{
    /* font */
    color: var(--green);
    font-family: Medium;
    font-size: 18px;
    text-align: left;
    vertical-align: top;
    /* box */
    padding: 8px;
    padding-bottom: 2px;
}
.fixed-table.rest th{
    /* box */
    padding-top: 16px;
    border-top: 2px solid var(--palegrey);
}
.fixed-table td{
    /* font */
    color: var(--black);
    font-family: Regular;
    font-size: 16px;
    text-align: left;
    vertical-align: top;
    /* box */
    padding: 0 8px;
    padding-bottom: 20px;
}
.fixed-table.last td{
    /* box */
    padding-bottom: 0;
}
.fixed-table.rest.edit-mode th {
    /* box */
    border-top: 2px solid var(--white);
}
.fixed-table.edit-mode td {
    /* box */
    padding: -4px 8px;
}
.fixed-table th:nth-child(1),
.fixed-table td:nth-child(1) {
    width: 20%;
}
.fixed-table th:nth-child(2),
.fixed-table td:nth-child(2) {
    width: 20%;
}
.fixed-table th:nth-child(3),
.fixed-table td:nth-child(3) {
    width: 20%;
}
.fixed-table th:nth-child(4),
.fixed-table td:nth-child(4) {
    width: 20%;
}
.fixed-table th:nth-child(5),
.fixed-table td:nth-child(5) {
    width: 20%;
}
.fixed-table select,
.fixed-table input{
    /* font */
    background-color: var(--white);
    color: var(--black);
    caret-color: var(--grey);
    font-family: Regular;
    font-size: 16px;
    /* box */
    width: 100%;
    padding: 2px 6px;
    margin-bottom: 4px;
    box-sizing: border-box;
    box-shadow: 0 1px 5px var(--blackopa);
    border: none;
    border-radius: 8px;
    outline: none;
}
.fixed-table input{
    padding-left: 10px;
}
.fixed-table input::placeholder{
    color: var(--grey);
}
.fixed-table input:focus::placeholder{
    color: transparent;
}

/* 4. Detail Table */
.detail-table {
    /* box */
    width: 100%;
    border-collapse: collapse;
    table-layout: fixed;
}
.detail-table th {
    /* font */
    color: var(--brown);
    font-family: Medium;
    font-size: 18px;
    text-align: left;
    vertical-align: top;
    /* box */
    padding: 8px;
}
.detail-table td {
    /* font */
    color: var(--black);
    font-family: Regular;
    font-size: 16px;
    text-align: left;
    vertical-align: top;
    /* box */
    padding: 8px;
    border-top: 2px solid var(--palegrey);
}
.detail-table.request-mode td {
    /* box */
    padding: 4px 8px;
    border-top: 2px solid var(--white);
}
.detail-table th:nth-child(1),
.detail-table td:nth-child(1) {
    width: 19%;
}
.detail-table th:nth-child(2),
.detail-table td:nth-child(2) {
    width: 19%;
}
.detail-table th:nth-child(3),
.detail-table td:nth-child(3) {
    width: 19%;
}
.detail-table th:nth-child(4),
.detail-table td:nth-child(4) {
    width: 19%;
}
.detail-table th:nth-child(5),
.detail-table td:nth-child(5) {
    width: 19%;
}
.detail-table th:nth-child(6),
.detail-table td:nth-child(6) {
    width: 5%;
}
.detail-table select,
.detail-table input{
    /* font */
    background-color: var(--white);
    color: var(--black);
    caret-color: var(--grey);
    font-family: Regular;
    font-size: 16px;
    /* box */
    width: 100%;
    padding: 2px 6px;
    margin: 2px 0;
    box-sizing: border-box;
    box-shadow: 0 1px 5px var(--blackopa);
    border: none;
    border-radius: 8px;
    outline: none;
}
.detail-table input{
    padding-left: 10px;
}
.detail-table input::placeholder{
    color: var(--grey);
}
.detail-table input:focus::placeholder{
    color: transparent;
}
.check-box{
    display: flex;
}
.detail-table input[type="checkbox"]{
    background: none;
    box-shadow: none;
    border: none;
    outline: none;
}

/* 5. Edit */
.profile-whitecard button.change{
    /* font */
    background-color: var(--green);
    color: var(--white);
    font-family: Medium;
    font-size: 16px;
    text-decoration: none;
    cursor: pointer;
    /* box */
    width: 100px;
    padding: 5px 15px;
    border: 2px var(--green) solid;
    border-radius: 30px;
    position: absolute;
    right: 3%;
    bottom: 6%;
}
.profile-whitecard button.change:hover{
    /* font */
    background-color: var(--yellow);
    /* box */
    border: 2px var(--yellow) solid;
}
.profile-whitecard.edit-mode,
.profile-whitecard.request-mode{
    /* display */
    background-color: var(--palegrey);
}
.profile-whitecard button.addStudy,
.profile-whitecard button.addJob{
    /* font */
    background-color: var(--yellow);
    color: var(--white);
    font-family: Medium;
    font-size: 16px;
    text-decoration: none;
    cursor: pointer;
    /* box */
    padding: 5px 15px;
    border: 2px var(--yellow) solid;
    border-radius: 30px;
}
.profile-whitecard button.addStudy:hover,
.profile-whitecard button.addJob:hover{
    /* font */
    background-color: var(--white);
    color: var(--yellow);
}
.detail-table .delete-cell{
    text-align: center;
    vertical-align: middle;
}
.delete-button{
    /* font */
    background-color: var(--yellow);
    color: var(--white);
    font-family: Medium;
    font-size: 25px;
    text-align: center;
    line-height: 1;
    text-decoration: none;
    cursor: pointer;
    /* box */
    width: 30px;
    height: 30px;
    padding: 0;
    border: 2px var(--yellow) solid;
    border-radius: 50%;
    margin: 4px 0;
}