.JobContainer {
    width: 95%;
    padding-bottom: 60px;
    position: relative;
}
.job-tabs {
    /* display */
    display: flex;
    justify-content: space-around;
    /* color block */
    background-color: var(--palegreen);
    position: absolute;
    top: 0;
    left: 0;
    /* box */
    width: 42%;
    border-radius: 20px 20px 0 0;
}
.job-tabs button {
    /* color block */
    background-color: var(--green);
    /* font */
    color: var(--white);
    font-family: Medium;
    font-size: 18px;
    cursor: pointer;
    /* box */
    padding: 20px 0;
    border: none;
    border-radius: 30px 30px 0 0;
    flex: 1;
    transition: background-color 0.1s;
}
.job-tabs button.active {
    /* color block */
    background-color: var(--yellow);
    /* box */
    padding: 20px 0;
    transform: none;
    border-radius: 30px 30px 0 0;
}
.job-request-form {
    /* color block */
    background-color: var(--white);
    position: relative;
    margin-top: 65px;
    /* box */
    width: 100%;
    height: 460px;
    border-radius: 0 8px 8px 8px;
    box-shadow: 0 2px 10px var(--blackopa);
}
.job-request-form form{
    /* box */
    width: 100%;
    padding: 40px;
    box-sizing: border-box;
}
.job-request-form-grid{
    /* display */
    display: grid;
    grid-template-columns: 49% 49%;
    /* box */
    gap: 16px 2%;
}
.job-request-form input[type="text"],
.job-request-form input[type="url"],
.job-request-form input[type="date"],
.job-request-form textarea,
.job-request-form select {
    /* font */
    background-color: var(--palegrey);
    color: var(--black);
    caret-color: var(--grey);
    font-family: Regular;
    font-size: 16px;
    /* box */
    width: 100%;
    padding: 12px;
    box-sizing: border-box;
    box-shadow: 0 1px 5px var(--blackopa);
    border: none;
    border-radius: 8px;
    outline: none;
}
.job-request-form textarea {
    /* box */
    width: 100%;
    max-width: 100%;
    min-height: 160px;
    max-height: 160px;
    margin-top: 16px;
}
.char-count {
    /* font */
    color: var(--grey);
    font-family: Regular;
    font-size: 14px;
    text-align: right;
    /* box */
    margin-top: 4px;
}
.job-request-form input:focus,
.job-request-form textarea:focus{
    outline: none;
}
.job-request-form .submit-container {
    /* box */
    text-align: center;
}
.job-request-form button {
    /* font */
    background-color: var(--yellow);
    color: var(--white);
    font-family: Medium;
    font-size: 18px;
    text-decoration: none;
    cursor: pointer;
    /* box */
    padding: 10px 40px;
    margin-top: 10px;
    box-shadow: 0 1px 5px var(--blackopa);
    border: 2px var(--yellow) solid;
    border-radius: 30px;
}
.job-request-form button:hover {
    /* font */
    background-color: var(--green);
    /* box */
    border: 2px var(--green) solid;
}
.submitted-jobs {
    /* color block */
    background-color: var(--white);
    position: relative;
    margin-top: 65px;
    padding: 25px;
    /* box */
    width: 100%;
    height: fit-content;
    border-radius: 0 8px 8px 8px;
    box-shadow: 0 2px 10px var(--blackopa);
}
.submitted-jobs-list {
    padding: 40px;
}
.job-table {
    /* box */
    width: 100%;
    border-collapse: collapse;
    table-layout: fixed;
}
.job-table th {
    /* font */
    color: var(--brown);
    font-family: Medium;
    font-size: 18px;
    text-align: left;
    vertical-align: top;
    /* box */
    padding: 8px;
    border-bottom: 2px solid var(--palegrey);
}
.job-table td {
    /* font */
    color: var(--black);
    font-family: Regular;
    font-size: 16px;
    text-align: left;
    vertical-align: top;
    /* box */
    padding: 8px;
    border-top: 2px solid var(--palegrey);
}
.job-table th:nth-child(1),
.job-table td:nth-child(1) {
    width: 55%;
}
.job-table th:nth-child(2),
.job-table td:nth-child(2) {
    width: 15%;
}
.job-table th:nth-child(3),
.job-table td:nth-child(3) {
    width: 15%;
}
.job-table th:nth-child(4),
.job-table td:nth-child(4) {
    width: 15%;
}
.job-pagination {
    /* display */
    display: flex;
    justify-content: center;
    list-style: none;
    /* box */
    position: absolute;
    bottom: 20px;
    left: 50%;
    transform: translateX(-50%);
    padding: 0;
}
.job-pagination li {
    /* display */
    display: inline;
    /* box */
    margin: 0 5px;
}
.job-pagination li a {
    /* font */
    color: var(--grey);
    font-family: Regular;
    font-size: 16px;
    text-decoration: none;
    cursor: pointer;
}
.job-pagination li.active a {
    /* font */
    color: var(--black);
}