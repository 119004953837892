.JobContainer {
    width: 95%;
    padding-bottom: 60px;
    position: relative;
}

.grade-tabs {
    /* display */
    display: flex;
    justify-content: space-around;
    /* color block */
    background-color: var(--palegreen);
    position: absolute;
    top: 0;
    left: 0;
    /* box */
    width: 52%;
    border-radius: 20px 20px 0 0;
}
.grade-tabs button {
    /* color block */
    background-color: var(--green);
    /* font */
    color: var(--white);
    font-family: Medium;
    font-size: 18px;
    cursor: pointer;
    /* box */
    padding: 20px 0;
    border: none;
    border-radius: 30px 30px 0 0;
    flex: 1;
    transition: background-color 0.1s;
}
.grade-tabs button.active {
    /* color block */
    background-color: var(--yellow);
    /* box */
    padding: 20px 0;
    transform: none;
    border-radius: 30px 30px 0 0;
}

.comb-form {
    background-color: var(--white);
    position: relative;
    top: 62px;
    width: 100%;
    height: auto;
    border-radius: 0 8px 8px 8px;
    box-shadow: 0 2px 10px var(--blackopa);
}
.comb-list{
    background-color: var(--white);
    position: relative;
    top: 62px;
    width: 100%;
    padding: 40px;
    height: auto;
    border-radius: 0 8px 8px 8px;
    box-shadow: 0 2px 10px var(--blackopa);
}

.comb-item{
    font-family: Light;
    font-size: 14px;
    padding: 10px;
    height: 1.5rem;
    display: flex;
    border-radius: 12px;
    border: 1px solid #ccc;
    transition: background-color 0.3s ease;
    text-align: center;
    margin-bottom: 10px;
}

.comb-item:hover{
    background-color:#eaeaea;
  }

.comb-item p{
    text-align: center;
    align-self: center;
}
.comb-form form {
    width: 100%;
    padding: 40px;
    box-sizing: border-box;
}
.comb-form-grid {
    display: grid;
    grid-template-columns: 100%;
    gap: 12px 2%;
}
.comb-form input[type="text"],
.comb-form select {
    background-color: var(--palegrey);
    color: var(--black);
    caret-color: var(--grey);
    font-family: Regular;
    font-size: 16px;
    width: 100%;
    padding: 12px;
    box-sizing: border-box;
    box-shadow: 0 1px 5px var(--blackopa);
    border: none;
    border-radius: 8px;
    outline: none;
}
.comb-form input:focus,
.comb-form textarea:focus {
    outline: none;
}
.comb-form .submit-container {
    text-align: center;
}
.comb-form button {
    background-color: var(--yellow);
    color: var(--white);
    font-family: Medium;
    font-size: 18px;
    text-decoration: none;
    cursor: pointer;
    padding: 10px 40px;
    margin-top: 10px;
    box-shadow: 0 1px 5px var(--blackopa);
    border: 2px var(--yellow) solid;
    border-radius: 30px;
}
.comb-form button:hover {
    background-color: var(--green);
    border: 2px var(--green) solid;
}

.pagination{
    display: flex;
    width: 100%;
    margin-top: 60px;
}

.alu-pagination {
    /* display */
    display: flex;
    justify-content: center;
    list-style: none;
    /* box */
    width: 100%;
    padding: 0;
  
}
.alu-pagination li {
    /* display */
    display: inline;
    /* box */
    margin: 0 5px;
}
.alu-pagination li a {
    /* font */
    color: var(--grey);
    font-family: Regular;
    font-size: 16px;
    text-decoration: none;
    cursor: pointer;
}
.alu-pagination li.active a {
    /* font */
    color: var(--black);
}

.comb-delete-button:hover {
    background-color: var(--green);
  }
  
  .comb-delete-button {
    background-color: var(--coffee);
    align-self: center;
  color: var(--white);
  height: fit-content;
  margin-left: 10px;
  font-family: light;
  border: none;
  font-size: 10px;
  text-decoration: none;
  padding: 8px;
  border-radius: 17px;
  }

  .comb-back-button {
    color: var(--brown);
    font-family: Medium;
    font-size: 16px;
    text-decoration: none;
    transition: 0.1s;
    cursor: pointer;
    position: absolute;
    top: 30px;
    right: 30px;
    background: none;
    border: none;
  }
  
  .comb-back-button button:hover{
    color: var(--yellow);
    
  }