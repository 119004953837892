.mentor-card {
    /* display */
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    /* color block */
    background-color: var(--white);
    border: 2px var(--green) solid;
    /* box */
    overflow: hidden;
    position: relative;
    width: 100%;
    border-radius: 8px;
}

.mentor-card button{
    background-color: var(--coffee);
    color: var(--white);
    border: none;
    border-radius: 8px;
    padding: 8px 16px;
    font-size: 1rem;
    margin: 15px;
    cursor: pointer;

}

.mentor-card button:hover {
    background-color: var(--brown);
   
}
.mentor-card-header {
    /* font */
    color: var(--brown);
    font-family: Medium;
    font-size: 16px;
    line-height: 1.3; 
    /* box */
    height: calc((100vh - 200px) * 0.05);
    min-height: 50px;
    padding-top: 20px;
    padding-left: 30px;
    padding-right: 30px;
}
.mentor-des {
    /* font */
    color: var(--black);
    font-family: Regular;
    font-size: 14px;
    line-height: 1.4; 
    /* box */
 
    min-height: 50px;
    padding-left: 30px;
    padding-right: 30px;
}
.apply-by{
    /* font */
    color: var(--black);
    font-family: Light;
    font-size: 12px;
    line-height: 1.2; 
    /* box */
    padding-left: 30px;
    padding-right: 30px;
}
