.job-page {
    /* box */
    width: 95%;
    padding-bottom: 60px;
    position: relative;
}
.job-cards-container {
    /* display */
    display: grid;
    justify-content: center;
    grid-template-columns: repeat(3, 1fr);
    /* box */
    width: 100%;
    gap: 40px;
}
.job-page .tabs {
    /* display */
    display: flex;
    justify-content: center;
    /* box */
    width: 100%;
    position: relative;
    margin-bottom: 40px;
    gap: 20px;
}
.job-page .tabs button {
    /* font */
    color: var(--brown);
    font-family: Medium;
    font-size: 18px;
    text-decoration: none;
    transition: 0.1s;
    cursor: pointer;
    /* box */
    position: relative;
    background: none;
    border: none;
    margin: 0 10px;
}
.job-page .tabs button:hover,
.job-page .tabs button.active {
    /* font */
    color: var(--yellow);
    border-bottom: 2px var(--yellow) solid;
}
.job-page .create-new-button {
    /* font */
    background-color: var(--green);
    color: var(--white);
    font-family: Medium;
    font-size: 16px;
    text-decoration: none;
    cursor: pointer;
    /* box */
    padding: 5px 15px;
    border: 2px var(--green) solid;
    border-radius: 30px;
    margin-bottom: 15px;
    position: absolute;
    top: -50px;
    left: 0;
}
.job-page .create-new-button:hover {
    /* font */
    background-color: var(--yellow);
    /* box */
    border: 2px var(--yellow) solid;
}