.mentors-page {
    /* box */
    width: 95%;
    padding-bottom: 60px;
}
.mentors-cards-container {
    /* display */
    display: grid;
    justify-content: center;
    grid-template-columns: repeat(3, 1fr);
    /* box */
    width: 100%;
    gap: 40px;
}
.mentors-page .tabs {
    /* display */
    display: flex;
    justify-content: center;
    /* box */
    width: 100%;
    position: relative;
    margin-bottom: 40px;
    gap: 40px;
}
.mentors-page .tabs button {
    /* font */
    color: var(--brown);
    font-family: Medium;
    font-size: 18px;
    text-decoration: none;
    transition: 0.1s;
    cursor: pointer;
    /* box */
    position: relative;
    background: none;
    border: none;
    margin: 0 10px;
}
.mentors-page .tabs button:hover,
.mentors-page .tabs button.active {
    /* font */
    color: var(--yellow);
    border-bottom: 2px var(--yellow) solid;
}

.applications-container {
    margin-top: 5px;
  }
  
  .applications-container table {
    width: 100%;
    border-collapse: collapse;
    font-family: Regular;
    border-radius: 10px;
 
  }

  .applications-container button {
    background-color: var(--yellow);
    color: var(--white);
    border: none;
    padding: 10px 15px;
    cursor: pointer;
    border-radius: 5px;
    margin-left: 10px;
  }

  .applications-container button:hover{
    background-color: var(--coffee);
  }
  
  .applications-container table, th, td {
    border: none;
    
    padding: 10px;
    padding-left: 15px;
    text-align: center;
  }
  
  .applications-container th {
    background-color: var(--coffee);  
    color: var(--white);
    font-family: Light;
    font-size: 16px;
  }
  
  .applications-container tr:nth-child(even) {
    background-color: rgb(255, 255, 255);
    font-family: light;
    font-size: 14px;
  }

    
  .applications-container tr:nth-child(odd) {
    background-color: #ffffff8d;
    font-family: light;
    font-size: 14px;
  }
  

  
  .applications-container th, .applications-container td {
    text-align: left;
  }

  