.ProfileWrapper{
    /* display */
    display: flex;
    flex-direction: column;
    /* box */
    position: relative;
    width: 95%;
    padding-bottom: 60px;
}
.toResume {
    /* font */
    color: var(--brown);
    font-family: Medium;
    font-size: 18px;
    text-decoration: none;
    transition: 0.1s;
    cursor: pointer;
    /* box */
    position: absolute;
    top: 0;
    right: 0;
    background: none;
    border: none;
}
.toResume:hover{
    /* font */
    color: var(--yellow);
    border-bottom: 2px var(--yellow) solid;
}