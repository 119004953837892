.EventsContainer {
  /* box */
  width: 95%;
  padding-bottom: 60px;
  position: relative;
}
.HeadWrapper {
  /* box */
  width: 100%;
  position: relative;
  height: 100px;
}
.calendar-view-button{
  /* font */
  background-color: var(--green);
  color: var(--white);
  font-family: Medium;
  font-size: 18px;
  text-decoration: none;
  cursor: pointer;
  /* box */
  padding: 8px 20px;
  margin-top: 10px;
  box-shadow: 0 1px 5px var(--blackopa);
  border: 2px var(--green) solid;
  border-radius: 30px;
  position: absolute;
  top: 0;
  right: 120px;
}
.calendar-view-button:hover {
  /* font */
  background-color: var(--yellow);
  /* box */
  border: 2px var(--yellow) solid;
}
.gallery-button {
  /* font */
  background-color: var(--brown);
  color: var(--white);
  font-family: Medium;
  font-size: 18px;
  text-decoration: none;
  cursor: pointer;
  /* box */
  padding: 8px 20px;
  margin-top: 10px;
  box-shadow: 0 1px 5px var(--blackopa);
  border: 2px var(--brown) solid;
  border-radius: 30px;
  position: absolute;
  top: 0;
  right: 0;
}
.gallery-button:hover {
  /* font */
  background-color: var(--yellow);
  /* box */
  border: 2px var(--yellow) solid;
}
.add-new-event {
  /* font */
  background-color: var(--green);
  color: var(--white);
  font-family: Medium;
  font-size: 16px;
  text-decoration: none;
  cursor: pointer;
  /* box */
  padding: 5px 15px;
  border: 2px var(--green) solid;
  border-radius: 30px;
  margin-top: -20px;
  margin-bottom: 15px;
}
.add-new-event:hover {
  /* font */
  background-color: var(--yellow);
  /* box */
  border: 2px var(--yellow) solid;
}
.CardsWrapper {
  /* display */
  display: grid;
  place-items: center;
  grid-template-columns: repeat(3, 1fr);
  /* box */
  width: 100%;
  gap: 40px;
}
