.form {
  max-width: 600px;
  margin: 10px;
  margin-bottom: 10px;
  height: auto;
  padding: 2rem;
  
}

.alumni-forms {
  width: 90%;
  margin: 10px;
  margin-bottom: 70px;
  height: auto;
  padding: 2rem;
  background:var(--white);
  font-family: Regular;
  border-radius: 10px;
box-shadow: 0 2px 10px var(--blackopa);
margin: 50px;
}

.user-image-icon {
  border-radius: 50%;
  width: 100px;
  height: 100px;
  object-fit: cover;
  margin-bottom: 1rem;
}

.form-button {
  background: var(--green);
  color:var(--white);
  border: none;
  padding: 0.75rem 1.5rem;
  border-radius: 8px;
  cursor: pointer;
  font-size: 1rem;
}

.name {
font-size: 1rem;
font-family: Medium;
color: var(--brown);
}
.form-button:disabled {
  background: #ccc;
}
