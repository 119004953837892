.GradeContainer {
    width: 95%;
    padding-bottom: 60px;
    position: relative;
    font-family: Regular;
}
.grade-tabs {
    display: flex;
    justify-content: center;
    background-color: var(--palegreen);
    position: absolute;
    top: 0;
    left: 0;
    width: 42%;
    border-radius: 20px 20px 0 0;
}
.grade-tabs button {
    background-color: var(--green);
    color: var(--white);
    font-family: Medium;
    font-size: 18px;
    cursor: pointer;
    padding: 20px 0;
    border: none;
    border-radius: 30px 30px 0 0;
    flex: 1;
    transition: background-color 0.1s;
}
.grade-tabs button.active {
    background-color: var(--yellow);
    padding: 20px 0;
    transform: none;
    border-radius: 30px 30px 0 0;
}
.grade-request-form {
    background-color: var(--white);
    position: relative;
    top: 62px;
    width: 100%;
    height: auto;
    border-radius: 0 8px 8px 8px;
    box-shadow: 0 2px 10px var(--blackopa);
    padding: 40px;
    box-sizing: border-box;
    margin-bottom: 30px;
}
.grade-request-form-grid {
    display: grid;
    grid-template-columns: 49% 49%;
    gap: 12px 2%;
}
.grade-request-form input[type="text"],
.grade-request-form input[type="number"] {
    background-color: var(--palegrey);
    color: var(--black);
    caret-color: var(--grey);
    font-family: Regular;
    font-size: 16px;
    width: 100%;
    padding: 12px;
    box-sizing: border-box;
    box-shadow: 0 1px 5px var(--blackopa);
    border: none;
    border-radius: 8px;
    outline: none;
}
.family-info {
    margin-top: 20px;
}
.family-info-input {
    display: flex;
    gap: 10px;
}

.buttons{
    display: flex;
    flex-direction: row;
    gap: 30px;

}
.remove-family-btn {
    background-color: var(--white);
    color: var(--black);
    font-family: Medium;
    font-size: 18px;
    cursor: pointer;
    padding: 10px;
    border: none;
    border-radius: 8px;
    transition: background-color 0.1s;
}
.add-family-btn-container {
    text-align: center;
    margin-top: 20px;
}
.add-family-btn,
.delete {
    background-color: var(--yellow);
    color: var(--white);
    font-family: Regular;
    box-shadow: 0 2px 10px var(--blackopa);
    font-size: 14px;
    cursor: pointer;
    padding: 10px 10px;
    border: none;
    border-radius: 30px;
    margin-bottom: 10px;
    margin-top: 20px;
    text-align: center;
}
.add-family-btn:hover {
    background-color: var(--green);

}

.save-btn {
    background-color: var(--yellow);
    box-shadow: 0 0px 2px var(--blackopa);
    color: var(--white);
    font-family: Regular;
    font-size: 14px;
    cursor: pointer;
    padding: 10px 10px;
    border: none;
    border-radius: 30px;
    margin-bottom: 10px;
    margin-top: 30px;
    margin-right: 20px;
    text-align: center;
}
.save-btn:hover {
    background-color: var(--green);
   
}

.submit-container {
    text-align: center;
    margin-top: 20px;
}

.grades-list{
    background-color: var(--white);
    position: relative;
    top: 62px;
    width: 100%;
    height: auto;
    border-radius: 0 8px 8px 8px;
    box-shadow: 0 2px 10px var(--blackopa);
    padding: 40px;
    box-sizing: border-box;
    margin-bottom: 30px;
}


.grade-item button {
    margin-left: 30px;
    background-color: var(--coffee);
    box-shadow: 0 0px 2px var(--blackopa);
    color: var(--white);
    font-family: Regular;
    border: none;
    font-size: 14px;
    width: 65px;
    text-decoration: none;
    padding: 10px 10px;
    border-radius: 20px;
}

.grade-item button:hover {
    background-color: var(--brown);
}

.grade-item{
    padding: 10px;
    display: flex;
    align-items: center;
    font-size: 14px;
    width: 100%;
    height: 1.8rem;
    border-radius: 12px;
    border: 1px solid #ccc;
    margin-bottom: 10px;
    transition: background-color 0.3s ease;
  }

  .grade-item:hover{
    background-color:#eaeaea;
  }

