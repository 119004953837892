.alumni-list {
    /* color block */
    background-color: var(--white);
    box-shadow: 0 2px 10px var(--blackopa);
    /* box */
    width: 100%;
    height: 320px;
    overflow-y: auto;
    padding: 20px;
    border-radius: 8px;
    box-sizing: border-box;
}
.alumni-item {
    /* display */
    display: flex;
    align-items: center;
    /* box */
    padding: 10px;
    cursor: pointer;
    transition: background-color 0.1s ease;
    border-radius: 8px;
}
.alumni-item:hover {
    /* color block */
    background-color: var(--palegrey);
    border-radius: 8px;
}
.alumni-pic {
    /* box */
    width: 50px;
    height: 50px;
    border-radius: 50%;
    margin-left: 10px;
    margin-right: 20px;
}
.alumni-name {
    color: var(--black);
    font-family: Regular;
    font-size: 16px;
    line-height: 1.2;
}