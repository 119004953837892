.AddEventContainer {
  width: 95%;
  padding-bottom: 60px;
  position: relative;
}

.back-button {
  background-color: var(--green);
  color: white;
  border: none;
  padding: 10px 20px;
  cursor: pointer;
  margin-bottom: 20px;
  border-radius: 4px;
}

.event-tabs {
  display: flex;
  justify-content: space-around;
  background-color: var(--palegreen);
  position: absolute;
  top: 0;
  left: 0;
  width: 42%;
  border-radius: 20px 20px 0 0;
}

.event-tabs button {
  background-color: var(--green);
  color: var(--white);
  font-family: Medium;
  font-size: 18px;
  cursor: pointer;
  padding: 20px 0;
  border: none;
  border-radius: 30px 30px 0 0;
  flex: 1;
  transition: background-color 0.1s;
}

.event-tabs button.active {
  background-color: var(--yellow);
  padding: 20px 0;
  border-radius: 30px 30px 0 0;
}

.event-request-form {
  background-color: var(--white);
  position: relative;
  top: 65px;
  width: 100%;
  height: 420px;
  border-radius: 0 8px 8px 8px;
  box-shadow: 0 2px 10px var(--blackopa);
}

.event-request-form form {
  width: 100%;
  padding: 40px;
  box-sizing: border-box;
}

.event-request-form-grid {
  display: grid;
  grid-template-columns: 49% 49%;
  gap: 12px 2%;
}

.event-form-group input[type="text"],
.event-form-group input[type="datetime-local"],
.event-form-group input[type="file"],
.event-form-group textarea {
  background-color: var(--palegrey);
  color: var(--black);
  caret-color: var(--grey);
  font-family: Regular;
  font-size: 16px;
  width: 100%;
  padding: 12px;
  box-sizing: border-box;
  box-shadow: 0 1px 5px var(--blackopa);
  border: none;
  border-radius: 8px;
  outline: none;
}

.event-form-group textarea {
  width: 100%;
  max-width: 100%;
  min-height: 130px;
  max-height: 130px;
  margin-top: 12px;
}

.char-count {
  color: var(--grey);
  font-family: Regular;
  font-size: 14px;
  text-align: right;
}

.event-form-group input:focus,
.event-form-group textarea:focus {
  outline: none;
}

.event-submit-container {
  text-align: center;
}

.event-request-form button {
  background-color: var(--yellow);
  color: var(--white);
  font-family: Medium;
  font-size: 18px;
  text-decoration: none;
  cursor: pointer;
  padding: 10px 40px;
  margin-top: 10px;
  box-shadow: 0 1px 5px var(--blackopa);
  border: 2px var(--yellow) solid;
  border-radius: 30px;
}

.event-request-form button:hover {
  background-color: var(--green);
  border: 2px var(--green) solid;
}
