.News-card-container{
  /* display */
  display: flex;
  flex-direction: column;
  /* box */
  position: relative;
  width: 350px;
  height: fit-content;
  margin: 10px;
  overflow: hidden;
}
.News-card-container:hover .News-card-img{
  /* color block */
  transform: scale(1.05);
}
.News-card-img{
  /* box */
  width: 100%;
  height: 200px;
  object-fit: cover;
  box-shadow: 0px 6px 6px var(--blackopa);
  transition: transform 0.3s ease-in-out;
  cursor: pointer;
}
.News-card-description{
  /* font */
  color: var(--brown);
  font-family: Medium;
  font-size: 18px;
  line-height: 1.3;
  /* box */
  margin-top: 20px;
  margin-bottom: -5px;
}
.News-card-date{
  /* font */
  color: var(--black);
  font-family: Regular;
  font-size: 14px;
}