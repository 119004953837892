.search-bar {
  /* color block */
  background-color: var(--greenopa);
  /* font */
  color: var(--black);
  caret-color: var(--grey);
  font-family: Regular;
  font-size: 16px;
  /* box */
  width: 100%;
  height: 60px;
  padding: 20px;
  border: none;
  border-radius: 8px;
  outline: none;
  box-shadow: 0 1px 5px var(--blackopa);
  position: absolute;
  top: 0;
  left: 0;
  box-sizing: border-box;
}
.search-bar:focus {
  /* color block */
  outline: none;
}
.search-bar::placeholder {
  color: var(--grey);
}
.search-bar:focus::placeholder {
  color: transparent;
}