body {
    background-color: var(--palegreen);
}

/* 1. Home Header - Annie */
.HomeHeader{
    /* display */
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    /* color block */
    background-color: var(--white);
    position: fixed;
    top: 0;
    box-shadow: 0 6px 6px var(--blackopa);
    /* box */
    width: 100%;
    padding: 5px 60px;
    z-index: 1000;
    box-sizing: border-box;
}
.HomeHeaderLeft{
    /* display */
    display: inline-flex;
    align-items: center;
    /* box */
    width: 25%;
}
.HomeHeaderLeft img{
    /* box */
    width: 70px;
    margin-left: -15px;
}
.HomeHeaderLeft p{
    /* font */
    color: var(--green);
    font-family: Regular;
    font-size: 16px;
    line-height: 1.2;
}
.HomeHeaderRight{
    /* display */
    display: flex;
    align-items: center;
}
.HomeHeaderMenu{
    /* box */
    margin-right: 20px;
}
.HomeHeaderMenu a{
    /* display */
    display: inline-flex;
    align-items: center;
    /* font */
    color: var(--green);
    font-family: Medium;
    font-size: 18px;
    text-decoration: none;
    transition: 0.1s;
    /* box */
    margin: 0 10px;
}
.HomeHeaderMenu a:hover,
.HomeHeaderMenu a.active{
    /* font */
    color: var(--orange);
    border-bottom: 2px var(--orange) solid;
}
.HomeHeaderLogin{
    /* box */
    margin-right: 40px;
}
.HomeHeaderLogin button{
    /* font */
    background-color: var(--white);
    color: var(--orange);
    font-family: Medium;
    font-size: 18px;
    text-decoration: none;
    cursor: pointer;
    /* box */
    width: 100%;
    padding: 8px 20px;
    border: 2px var(--orange) solid;
    border-radius: 30px;
}
.HomeHeaderLogin button:hover{
    /* font */
    background-color: var(--orange);
    color: var(--white);
}
.HomeHeaderIcon{
    /* box */
    margin-top: 5px;
    margin-right: -5px;
}
.OrangeIcon{
    /* font */
    color: var(--orange);
    font-size: 20px;
    cursor: pointer;
    /* box */
    margin: 5px;
}

/* 2. Home Banner - Annie */
.HomeBanner{
    /* display */
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    /* color block */
    background-color: var(--palegreen);
    /* box */
    width: 100%;
    box-sizing: border-box;
}
.HomeBanner img{
    /* box */
    width: 100%;
}
.HomeBannerTitle{
    /* font */
    color: var(--green);
    font-family: Medium;
    font-size: 50px;
    text-align: center;
    transition: 0.2s;
    text-shadow: 2px 2px 4px var(--greyopa);
    /* box */
    width: 100%;
    margin-top: -10px;
    margin-bottom: -60px;
}
.HomeBannerTitle:hover{
    font-size: 55px;
}
.HomeBannerText{
    /* font */
    color: var(--black);
    font-family: Light;
    font-size: 18px;
    text-align: center;
    /* box */
    width: 100%;
    margin-bottom: 50px;
}

/* 3. Home Gallery - Anushka (Aligned) */
.gallery{
    /* display */
    display: flex;
    flex-direction: column;
    align-items: center;
    /* color block */
    background-color: var(--palegreen);
    /* box */
    position: relative;
    padding: 30px;
    margin-bottom: -60px;
}
.GalleryText{
    /* display */
    display: inline-flex;
    align-items: center;
    /* box */
    width: 70%;
    margin-top: 40px;
}
.GalleryText p{
    /* font */
    color: var(--black);
    font-family: Light;
    font-size: 18px;
}
.GalleryBold{
    /* font */
    color: var(--orange);
    font-family: Medium
}
.GalleryText button{
    /* font */
    background-color: var(--orange);
    color: var(--white);
    font-family: Medium;
    font-size: 18px;
    text-decoration: none;
    cursor: pointer;
    /* box */
    padding: 10px 40px;
    box-shadow: 0 1px 5px var(--blackopa);
    border: 2px var(--orange) solid;
    border-radius: 30px;
    margin-left: 25px;
    white-space: nowrap;
}
.GalleryText button:hover {
    /* font */
    background-color: var(--green);
    color: var(--white);
    /* box */
    border: 2px var(--green) solid;
}

/* 4. Home Gender - Annie */
/* 5. Home Combination - Annie */
.charts{
    /* display */
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    /* color block */
    background-color: var(--palegreen);
    /* box */
    width: 100%;
    padding: 5px 250px;
    box-sizing: border-box;
}
.Mission{
    /* font */
    color: var(--orange);
    font-family: Bold;
    font-size: 24px;
    text-align: center;
    letter-spacing: 0.9px;
    /* box */
    width: 100%;
    margin-top: 200px;
    margin-bottom: 50px;
    padding: 5px;
    box-sizing: border-box;
}

/* 6. Home News - Anushka (Aligned) */
/* 7. Home Alumni - Anushka (Aligned) */
.cards{
    /* color block */
    background-color: var(--palegreen);
}
.cards-title{
    /* color block */
    background-color: var(--palegreen);
    /* box */
    padding: 0 120px;
    padding-top: 20px;
}
.cards-title h1{
    /* font */
    color: var(--brown);
    font-family: Bold;
    font-size: 24px;
    letter-spacing: 0.9px;
    /* box */
    margin-bottom: -5px;
}
.cards-title p{
    /* font */
    color: var(--coffee);
    font-family: Regular;
    font-size: 18px;
    /* box */
    padding-top: 0;
    margin-top: 10px;
    margin-bottom: 40px;
}
.cards-wrapper{
    /* display */
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    /* color block */
    background-color: var(--palegreen);
    /* box */
    gap: 60px;
}
.view-button-news{
    /* display */
    display: flex;
    justify-content: center;
    /* box */
    padding: 15px 0
}
.view-button-alumni{
    /* display */
    display: flex;
    justify-content: center;
    /* box */
    padding: 35px 0
}
.ViewMore{
    /* font */
    background-color: var(--orange);
    color: var(--white);
    font-family: Medium;
    font-size: 18px;
    text-decoration: none;
    cursor: pointer;
    /* box */
    padding: 10px 40px;
    margin-top: 10px;
    box-shadow: 0 1px 5px var(--blackopa);
    border: 2px var(--orange) solid;
    border-radius: 30px;
}
.ViewMore:hover{
    /* font */
    background-color: var(--green);
    /* box */
    border: 2px var(--green) solid;
}

/* 8. Home Footer - Annie */
.HomeFooter{
    /* display */
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    /* color block */
    background-color: var(--black);
    /* box */
    width: 100%;
    padding: 40px 100px;
    box-sizing: border-box;
    margin-top: 60px;
}
.HomeFooterLeft{
    /* display */
    display: inline-flex;
    align-items: top;
}
.HomeFooterAddress{
    /* box */
    margin-right: 60px;
}
.HomeFooterText{
    /* font */
    color: var(--white);
    font-family: Light;
    font-size: 14px;
}
.HomeFooterBold{
    /* font */
    font-family: Medium;
}
.HomeFooterIcon{
    /* box */
    margin-left: -5px;
}
.WhiteIcon{
    /* font */
    color: var(--white);
    font-size: 20px;
    /* box */
    margin: 5px;
    cursor: pointer
}
.HomeFooterRight{
    /* display */
    display: inline-flex;
    align-items: center;
}
.HomeFooterRight img{
    /* box */
    width: 120px;
    padding: 20px;
}

/* 9. Login Pop Up - Annie */
.errmsg {
    /* display */
    display: block;
    /* font */
    color: var(--brown);
    font-family: Regular;
    text-align: center;
    /* box */
    margin-top: -10px;
    margin-bottom: -2px;
}
.offscreen {
    /* color block */
    opacity: 0;
    position: absolute;
    visibility: hidden;
}
.PopUpOverlay{
    /* display */
    display: flex;
    align-items: center;
    justify-content: center;
    /* color block */
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: var(--blackopa);
    z-index: 1001;
}
.PopUpWindow{
    /* color block */
    background-color: var(--palegrey);
    position: relative;
    z-index: 1002;
    /* box */
    width: 370px;
    padding: 30px 30px;
    box-shadow: 0 2px 10px var(--blackopa);
    border-radius: 8px;
}
.PopUpWindow label{
    /* display */
    display: block;
}
.PopUpWindow input {
    /* font */
    background-color: var(--white);
    color: var(--black);
    caret-color: var(--grey);
    font-family: Regular;
    font-size: 16px;
    /* box */
    width: 100%;
    padding: 12px;
    margin: 10px 0;
    box-sizing: border-box;
    box-shadow: 0 1px 5px var(--blackopa);
    border: none;
    border-radius: 8px;
    outline: none;
}
.PopUpWindow input::placeholder {
    color: var(--grey);
}
.PopUpWindow input:focus::placeholder {
    color: transparent;
}
.LoginTitle{
    /* display */
    display: inline-flex;
    align-items: center;
}
.LoginTitle img{
    /* box */
    width: 110px;
    margin-left: -10px;
}
.LoginTitle p{
    /* font */
    color: var(--green);
    font-family: Medium;
    font-size: 22px;
    line-height: 1.2;
}
.ConfirmButton{
    /* display */
    display: flex;
    justify-content: center;
}
.ConfirmButton button{
    /* font */
    background-color: var(--yellow);
    color: var(--white);
    font-family: Medium;
    font-size: 18px;
    text-decoration: none;
    cursor: pointer;
    /* box */
    padding: 10px 40px;
    margin-top: 10px;
    box-shadow: 0 1px 5px var(--blackopa);
    border: 2px var(--yellow) solid;
    border-radius: 30px;
}
.ConfirmButton button:hover {
    /* font */
    background-color: var(--green);
    /* box */
    border: 2px var(--green) solid;
}
.CloseButton{
    /* font */
    color: var(--grey);
    font-family: Medium;
    font-size: 26px;
    cursor: pointer;
    /* color block */
    position: absolute;
    background: none;
    border: none;
    /* box */
    top: 10px;
    right: 15px;
}
.CloseButton:hover{
    /* font */
    color: var(--brown);
}
.ForgetPassword{
    /* display */
    display: block;
    /* font */
    color: var(--grey);
    font-family: Regular;
    text-align: center;
    text-decoration: none;
    cursor: pointer;
    /* box */
    margin-top: 10px;
}
.ForgetPassword:hover{
    /* font */
    color: var(--brown);
    text-decoration: underline;
}