.dropzone {
    border: 0px dashed #007bff;
    padding: 0px;
    text-align: center;
    cursor: pointer;
  }
  
  .dropzone img {
    max-width: 100%;
    max-height: 70px;
    margin-top: 0px;
  }
  