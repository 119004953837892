.NewsContainer {
  width: 95%;
  padding-bottom: 60px;
  position: relative;
}

.news-tabs {
  display: flex;
  justify-content: space-around;
  background-color: var(--palegreen);
  position: absolute;
  top: 0;
  left: 0;
  width: 72%;
  border-radius: 20px 20px 0 0;
}

.news-tabs button {
  background-color: var(--green);
  color: var(--white);
  font-family: Medium;
  font-size: 18px;
  cursor: pointer;
  padding: 20px 0;
  border: none;
  border-radius: 30px 30px 0 0;
  flex: 1;
  transition: background-color 0.1s;
}

.news-tabs button.active {
  background-color: var(--yellow);
  padding: 20px 0;
  transform: none;
  border-radius: 30px 30px 0 0;
}

.news-request-form {
  background-color: var(--white);
  position: relative;
  top: 55px;
  width: 100%;
  height: 420px;
  border-radius: 0 8px 8px 8px;
  box-shadow: 0 2px 10px var(--blackopa);
}

.news-request-form form {
  width: 100%;
  padding: 40px;
  box-sizing: border-box;
  font-family: Regular;
}

.news-request-form-grid {
  display: grid;
  grid-template-columns: 49% 49%;
  gap: 12px 2%;
}

.news-request-form input[type="text"],
.news-request-form input[type="date"],
.news-request-form input[type="file"],
.news-request-form textarea {
  background-color: var(--palegrey);
  color: var(--black);
  caret-color: var(--grey);
  font-family: Regular;
  font-size: 16px;
  width: 100%;
  padding: 12px;
  box-sizing: border-box;
  box-shadow: 0 1px 5px var(--blackopa);
  border: none;
  border-radius: 8px;
  outline: none;
}

.news-request-form textarea {
  width: 100%;
  max-width: 100%;
  min-height: 130px;
  max-height: 130px;
  margin-top: 12px;
}

.char-count {
  color: var(--grey);
  font-family: Regular;
  font-size: 14px;
  text-align: right;
}

.news-request-form input:focus,
.news-request-form textarea:focus {
  outline: none;
}

.news-request-form .submit-container {
  text-align: center;
}

.news-request-form button {
  background-color: var(--yellow);
  color: var(--white);
  font-family: Medium;
  font-size: 18px;
  text-decoration: none;
  cursor: pointer;
  padding: 10px 40px;
  margin-top: 10px;
  box-shadow: 0 1px 5px var(--blackopa);
  border: 2px var(--yellow) solid;
  border-radius: 30px;
}

.news-request-form button:hover {
  background-color: var(--green);
  border: 2px var(--green) solid;
}

.submitted-news {
  width: 100%;
  margin-top: 67px;
  padding: 40px;
  font-family: Regular;
  background-color: var(--white);
  box-shadow: 0 2px 10px var(--blackopa);
  border-radius: 0 20px 20px 20px;
  height: calc(95% - 62px);
}



.news-back-button {
  /* font */
  color: var(--brown);
  font-family: Medium;
  font-size: 16px;
  text-decoration: none;
  transition: 0.1s;
  cursor: pointer;
  /* box */
  position: absolute;
  top: 0;
  right: 0;
  background: none;
  border: none;
}
.news-back-button:hover{
  /* font */
  color: var(--yellow);
  border-bottom: 2px var(--yellow) solid;
}

.news-item {
  padding: 10px;
  display: flex;
  border-radius: 12px;
  border: 1px solid #ccc;
  height: 1.5rem;
  font-size: 14px;
  align-items: center;
  font-family: light;
  margin-bottom: 10px;
  transition: background-color 0.3s ease;
}

.news-item:hover {
 
  background-color: #eaeaea;
}

.news-delete-button:hover {
  background-color: var(--green);
}

.news-delete-button {
  background-color: var(--coffee);
color: var(--white);
height: fit-content;
margin-left: 10px;
font-family: light;
border: none;
font-size: 10px;
text-decoration: none;
padding: 8px;
border-radius: 17px;
}
.pdf-news-section{
  margin-top: 100px;
}


