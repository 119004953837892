/* 1. white card */
.alumni-detail {
    /* flex */
    display: flex;
    flex-direction: column;
    align-items: center;
    /* color block */
    background-color: var(--white);
    box-shadow: 0 2px 10px var(--blackopa);
    /* box */
    position: relative;
    min-height: 560px;
    overflow-y: auto;
    padding: 20px;
    border-radius: 8px;
    box-sizing: border-box;
}
/* 2. Profile */
.backToGraph {
    /* font */
    color: var(--brown);
    font-family: Medium;
    font-size: 16px;
    text-decoration: none;
    transition: 0.1s;
    cursor: pointer;
    /* box */
    position: absolute;
    top: 30px;
    right: 30px;
    background: none;
    border: none;
}
.backToGraph:hover{
    /* font */
    color: var(--yellow);
}
.detail-pic {
    /* box */
    width: 120px;
    border-radius: 50%;
    margin-top: 20px;
    margin-bottom: 10px;
}
.detail-first-name {
    /* font */
    color: var(--black);
    font-family: Medium;
    font-size: 20px;
    /* box */
    margin-bottom: -5px;
}
.detail-last-name {
    /* font */
    color: var(--black);
    font-family: Medium;
    font-size: 20px;
    /* box */
    margin-bottom: 5px;
}
.detail-contact-info {
    /* font */
    color: var(--black);
    font-family: Regular;
    font-size: 14px;
}
.detail-grid {
    /* display */
    display: grid;
    grid-template-columns: auto 1fr;
    /* box */
    width: 80%;
    padding: 20px;
    gap: 0 12px;
}
.DetailTitle {
    /* font */
    color: var(--green);
    font-size: 18px;
    font-family: Medium;
    /* box */
    padding: 3px 0;
}
.DetailValue {
    /* font */
    color: var(--black);
    font-size: 16px;
    font-family: Regular;
    /* box */
    padding: 6px 0;
    padding-left: 12px;
    border-left: 2px solid var(--palegrey);
}
.alumni-detail-button{
    display: flex;
    gap: 10px;
}
.alumni-detail-button button{
    /* font */
    font-family: Medium;
    font-size: 16px;
    text-decoration: none;
    cursor: pointer;
    /* box */
    width: 100px;
    padding: 5px 10px;
    border-radius: 30px;
    margin-bottom: 10px;
}
.alumni-view-button{
    background-color: var(--green);
    color: var(--white);
    border: 2px var(--green) solid;
}
.alumni-view-button:hover{
    /* font */
    background-color: var(--white);
    color: var(--green);
}
.alumni-delete-button{
    /* font */
    background-color: var(--grey);
    color: var(--white);
    border: 2px var(--grey) solid;
}
.alumni-delete-button:hover{
    /* font */
    background-color: var(--white);
    color: var(--grey)
}
.detail-empty-message{
    /* font */
    color: var(--green);
    font-family: Medium;
    font-size: 18px;
    /* box */
    margin-top: 20px;
}
/* 3. graph */
.detail-graph{
    /* box */
    width: 100%;
    margin-top: 20px;
}
.detail-graph-title{
    /* font */
    color: var(--brown);
    font-family: Medium;
    font-size: 18px;
    text-align: center;
}
.detail-graph-description{
    /* font */
    color: var(--coffee);
    font-family: Regular;
    font-size: 12px;
    text-align: center;
    /* box */
    margin-bottom: 10px;
}

/* 4. Zoomed In */
.lockScreen{
    /* display */
    display: flex;
    align-items: center;
    justify-content: center;
    /* color block */
    position: fixed;
    top: 80px;
    left: 280px;
    right: 0;
    bottom: 0;
    background: var(--blackopa);
    z-index: 1001;
}
.zoomedInContainer{
    /* display */
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    /* box */
    position: absolute;
    top: 60px;
    bottom: 60px;
    left: 60px;
    right: 60px;
}
.zoomedIn{
    /* color block */
    background-color: var(--white);
    position: relative;
    z-index: 1002;
    /* box */
    width: 95%;
    height: 100%;
    overflow-y: auto;
    padding: 30px;
    box-sizing: border-box;
    box-shadow: 0 2px 10px var(--blackopa);
    border-radius: 8px;

}
.big-space{
    height: 40px
}