.Alumni-card-container{
  /* display */
  display: flex;
  flex-direction: column;
  /* color block */
  background-color: var(--white);
  /* box */
  position: relative;
  width: 350px;
  height: 330px;
  margin: 10px;
  border-radius: 0 0 8px 8px;
  overflow: hidden;
  box-shadow: 0px 6px 6px var(--blackopa);
  transition: transform 0.3s ease-in-out;
}
.Alumni-card-img{
  /* box */
  width: 100%;
  height: 180px;
  overflow: hidden;
}
.Alumni-card-title{
  /* font */
  color: var(--brown);
  font-family: Medium;
  font-size: 18px;
  line-height: 1.3;
  /* box */
  padding: 10px 20px;
  margin-bottom: -5px;
}
.Alumni-card-description{
  /* font */
  color: var(--black);
  font-family: Regular;
  font-size: 14px;
  /* box */
  padding: 0 20px;
  position: absolute;
  bottom: 25px;
}
.Alumni-card-btn{
  /* font */
  color: var(--green);
  font-family: Regular;
  font-size: 14px;
  border: none;
  background: none;
  text-decoration: none;
  cursor: pointer;
  /* box */
  padding: 0 20px;
  margin-top: -15px;
  position: absolute;
  bottom: 15px;
}
.Alumni-card-btn:hover{
  /* font */
  color: var(--yellow);
  text-decoration: underline;
}