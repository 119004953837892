.dona-wrapper {
    /* box */
    width: 95%;
    padding-bottom: 60px;
    position: relative;
  }
  .dona-heading {
    /* box */
    width: 100%
  }
  .dona-title {
    /* font */
    color: var(--brown);
    font-family: Bold;
    font-size: 28px;
    line-height: 1.3;
    /* box */
    padding-bottom: 15px;
  }
  .dona-subtitle {
    /* font */
    color: var(--green);
    font-family: Medium;
    font-size: 18px;
  }

  .dona-wrapper {
    padding: 20px;
    font-family: Regular;
  }
  
  .dona-heading {
    margin-bottom: 20px;
  }
  
  .dona-title {
    font-size: 24px;
    font-weight: bold;
  }
  
  .dona-subtitle {
    font-size: 18px;
    margin-top: 10px;
  }
  
  .dona-text-container {
    margin-top: 20px;
  }
  
  .momo-code-card {
    background-color: var(--white);
    border: none;
    box-shadow: 0 0px 7px var(--blackopa);
    border-radius: 8px;
    padding: 15px;
    margin: 10px 0;
  }
  
  .momo-code-actions button,
  .momo-code-card button {
    background-color:var(--green);
    color: var(--white);
    border: none;
    padding: 10px 15px;
    margin-right: 5px;
    cursor: pointer;
    border-radius: 8px;
  }
  
  .momo-code-actions button:hover,
  .momo-code-card button:hover {
    background-color:var(--brown);
  }
  
  .add-momo-code button {
    background-color:var(--green);
    color: var(--white);
    border: none;
    padding: 10px 15px;
    cursor: pointer;
    border-radius: 5px;
    margin-top: 20px;
  }
  
  .add-momo-code button:hover {
    background-color: var(--brown);
  }
  
  .donations-list,
  .donations-status {
    margin-top: 40px;
    color: var(--black);
  }
  
  .donations-list h2,
  .donations-status h2 {
    margin-bottom: 20px;
  }
  
  .donations-list table,
  .donations-status table {
    width: 100%;
    border-collapse: collapse;
    box-shadow: 0 2px 5px var(--blackopa);
    border-radius: 10px;
  }
  
  .donations-list table,
  .donations-status table,
  th,
  td {
    border: none;
    padding: 8px;
    text-align: center;
   
  }
  
  .donations-list th,
  .donations-status th {
    background-color: var(--coffee);  
    color: var(--white);
    font-family: Light;
    font-size: 16px;
  }
  
  .donations-list tr:nth-child(even),
  .donations-status tr:nth-child(even) {
    background-color: var(--white);
  }

  .donations-list tr:nth-child(odd),
  .donations-status tr:nth-child(odd) {
    background-color: #f3ebf08d;
  }
  
  .donations-list tr:hover,
  .donations-status tr:hover {
    background-color: #c2c2c2;
  }
  