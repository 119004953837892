:root{
  --green: #498160;
  --greenopa: #49816030;
  --brown: #6d5736;
  --orange: #f49c46;
  --orangeli: #f4d2b0;
  --coffee: #957967;
  --coffeeli: #d6c7bd;
  --yellow: #d8b040;
  --black: #363636;
  --blackopa: #36363650;
  --grey: #828282;
  --greyopa: #82828290;
  --white: #F7F7F7;
  --palegrey: #D9D9D9;
  --palegreen: #EEF7F0;
  /* var(--color) */
}

@font-face{
  font-family: Regular;
  src: url(static/Poppins/Poppins-Regular.ttf)
}
/* text: regular, 16px */
    /* color: var(--green);
    font-family: Regular;
    font-size: 16px;
    line-height: 1.2; */
/* subtitle: regular 18px */
    /* color: var(--black);
    font-family: Light;
    font-size: 18px; */

@font-face{
  font-family: Medium;
  src: url(static/Poppins/Poppins-Medium.ttf)
}
/* button: medium, 18px */
    /* background-color: var(--white);
    color: var(--orange);
    font-family: Medium;
    font-size: 18px;
    text-decoration: none;
    width: 100%;
    padding: 10px 10px;
    border: 2px var(--orange) solid;
    border-radius: 30px; */

/* menu bar: medium, 18px */
    /* color: var(--green);
    font-family: Medium;
    font-size: 18px;
    text-decoration: none; */

@font-face{
  font-family: Light;
  src: url(static/Poppins/Poppins-Light.ttf)
}

@font-face{
  font-family: Bold;
  src: url(static/Poppins/Poppins-Bold.ttf)
}
/* section title: bold, 35px */
    /* color: var(--brown);
    font-family: Bold;
    font-size: 35px; */

body, html{
  margin: 0;
  padding: 0;
}

button:focus{
  outline: none;
}