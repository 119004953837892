.Dash-card-container{
  /* display */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  /* color block */
  background-color: var(--palegrey); 
  /* box */
  padding: 15px 0;
  position: relative;
  width: 100%;
  height: 100%;
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0px 6px 6px var(--blackopa);
  transition: transform 0.3s ease-in-out;
}
.Dash-card-img-container {
  /* display */
  display: flex;
  justify-content: center;
  align-items: center;
  /* box */
  width: calc((100vh - 200px) * 0.17);
  height: calc((100vh - 200px) * 0.17);
  background-color: var(--white);
  border-radius: 50%;
  margin-bottom: calc((100vh - 200px) * 0.05);
  box-shadow: 0 1px 5px var(--blackopa);
}
.Dash-card-img {
  /* box */
  width: calc((100vh - 200px) * 0.10);
  height: calc((100vh - 200px) * 0.10);
}
.Dash-card-btn{
  /* display */
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  /* font */
  background-color: var(--green);
  color: var(--white);
  font-family: Medium;
  font-size: 18px;
  line-height: 1.2; 
  text-align: center; 
  text-decoration: none;
  cursor: pointer;
  /* box */
  width: 60%;
  padding: 5px 15px;
  box-shadow: 0 1px 5px var(--blackopa);
  border: 2px var(--green) solid;
  border-radius: 25px;
}
.Dash-card-btn:hover{
  /* font */
  background-color: var(--brown);
  color: var(--white);
  /* box */
  border: 2px var(--brown) solid;
}
